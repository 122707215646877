* {
  font-family: 'SF Pro Text', system-ui, sans-serif;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 text-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  text: block;
}

html, body {
  height: 100%;
  font-family: 'SF Pro Text', system-ui, sans-serif;
}

body {
  line-height: 1;
}

:root {
  --text-color: #010101;
  --single-choice-button-border: none;
  --single-choice-button-border-selected: none;
  --multi-choice-button-border: none;
  --multi-choice-button-border-selected: none;
  --reviews-io-widget-star-color: #EB731A;
  --reviews-io-widget-star-color-disabled: rgba(235, 115, 26, 0.5);
  --reviews-io-widget-header-star-color-disabled: rgba(32, 43, 71, 0.5);
  --reviews-io-widget-text-color: #010101;
  --reviews-io-widget-background-color: #FFFFFF;
}

ol, ul {
  list-style: none;
}

/* Google address autocomplete dropdown */
.pac-container {
  background-color: #FAFAFA;
  font-family: 'SF Pro Text', -apple-system, sans-serif;
}

.pac-item,
.pac-item-query {
  color: #010101;
}

.pac-item:hover {
  background: #6666;
}
